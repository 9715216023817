import Vue from 'vue'
import VueRouter from 'vue-router'
import INDEX from '../views/index.vue'
import exhibition from '../views/exhibition.vue'
import international from '../views/international.vue'
import jinpeng from '../views/jinpeng.vue'
import news from '../views/news.vue'
import newdetails from '../views/newdetails.vue'
import internationals from '../views/internationals.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: INDEX
  },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: exhibition
  },
  {
    path: '/international',
    name: 'international',
    component: international
  },
  {
    path: '/jinpeng',
    name: 'jinpeng',
    component: jinpeng
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/newdetails',
    name: 'newdetails',
    component: newdetails
  },
  {
    path: '/internationals',
    name: 'internationals',
    component: internationals
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


let originPush = VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}


  export default router
