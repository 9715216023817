<template>
<div class="exhibition-contact">
<!-- 轮播图 -->
<!-- slideshow -->
<div
style="padding-bottom: 24px; background-color: #edeef2"
v-if="bannerList.length > 0">
<slideshow
:backgroundColor="'#edeef2'"
:bannerList="bannerList"
></slideshow>
</div>

<!-- 导航栏 -->
<div class="nav-bar" id="navBarEx">
<div
class="bar-iten"
@click="navClick(item)"
:class="navIndex == item.id ? 'activate-nav' : ''"
v-for="item in exhibitionList"
:key="item.id"
 >
{{ item.title }}
</div>
</div>

<div class="rich-contact">
<div class="video-html">
<div class="video-contact">
<video
style="width: 100%; height: 100%"
:src="videoUrls"
class="video-avatar"
:autoplay="false"
controls="controls"
 muted
 loop
></video>
</div>
<div class="html-contact">
<div v-html="exhibitionHtml" :class="['quill', 'ql-editor']"></div>
</div>
</div>
</div>
<div class="footer">
        <div class="footers">
        <div class="footer-fl-titler">
            <h1  >{{formfooter.web_footname}} </h1>
            <!-- <h2>Office of the Foreign Affairs Commission of Pengzhou Municipal </h2> -->
        </div>


        <div class="footer-container">
            <div class="fl">

                <div class="phone" v-if="foot == 'zh'">咨询电话：</div>
                <div class="phone" v-if="foot == 'en'">Consultation hotline: </div>
                
                <div class="phone-num">{{ formfooter.web_phone }}</div>

                <div class="text">
                    <div><span v-if="foot == 'zh'">参观地址：{{ formfooter.web_address }}</span>
                    <span v-if="foot == 'en'">Visit address: {{ formfooter.web_address }}</span></div>
                    <div style="margin-top: 20px;"><span v-if="foot == 'zh'" >参观时间：{{ formfooter.web_qq }}</span>
                    <span v-if="foot == 'en'" >Visit time: {{ formfooter.web_qq }}</span></div>
                </div>

                <div class="text" style="margin: 24px 0;">
                    <span v-if="foot == 'zh'">网址：{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'en'">Website:{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'zh'" style="margin-left: 110px;">邮编：611900 </span>
                    <span v-if="foot == 'en'" style="margin-left: 110px;">Postal Code: 611900</span>
                    <span v-if="foot == 'zh'" style="margin-left: 76px;">邮箱：{{ formfooter.web_email }}</span>
                    <span v-if="foot == 'en'" style="margin-left: 76px;">Email: {{ formfooter.web_email }}</span>
                </div>
            </div>

            <div class="fr">
                <div v-if="foot == 'zh'" class="fr-h2">获取更多最新信息</div>
                <div v-if="foot == 'en'" class="fr-h2">Get more up-to-date information</div>

                <div class="qr-containter">
                    <div class="qr-item">
                        <img src="http://lanzhi.580ck.com/static/images/ys3.png" alt="">

                        <span v-if="foot == 'zh'">投资彭州</span>
                        <span v-if="foot == 'en'">Investment in Pengzhou</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys2.png" alt="">

                        <span v-if="foot == 'zh'">彭州发布</span>
                        <span v-if="foot == 'en'">Pengzhou Release</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys1.jpg" alt="">

                        <span v-if="foot == 'zh'">品鉴彭州</span>
                        <span v-if="foot == 'en'">Tasting Pengzhou</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="filing">
            <span>
                {{ formfooter.web_copyright }}
            </span>
            <a class="filinglink" target="_blank" :href="formfooter.web_recordnum">{{ formfooter.web_recordnum}}</a>


        </div>
        </div>



    </div>
</div>
</template>

<script>
import Slideshow from "@/components/slideshow.vue";
import { listBanner } from "@/api/banner";
import { listIndex, listCity,listLingshi,listCopyright } from '@/api/index'
import { listExhibition } from "@/api/exhibition";
export default {
  components: {
    Slideshow,
  },
  data() {
    return {
      navIndex: 8,
      bannerList: [],
      exhibitionList: [],
      videoUrl: "",
      exhibitionHtml: "",
      // 表单参数
      form: {
        biz: "exhibition",
        type: localStorage.getItem("languageSet") || "zh",
        status: 1,
      },

      exhibitionForm: {
        type: localStorage.getItem("languageSet") || "zh",
      },
      videoUrls: "",
      formfooter:{},
            foot:localStorage.getItem('languageSet')
    };
  },
  methods: {
    navClick(item) {
      this.navIndex = item.id;
      this.videoUrls = item.file;
      this.exhibitionHtml = item.body;
    },
    getListBanner() {
      listBanner(this.form).then((res) => {
        this.bannerList = Array.isArray(res.rows) ? res.rows : [];
        this.getExhibition();
      });
    },
    getExhibition() {
      listExhibition(this.exhibitionForm).then((res) => {
        this.exhibitionList = res.rows;
        this.videoUrls = this.exhibitionList[0].file;
        this.exhibitionHtml = this.exhibitionList[0].body;
      });
    },
    getlistCopyright(){
      listCopyright({
          pageNum: 1,
          pageSize: 1,
          type:localStorage.getItem('languageSet') || 'zh',
      }).then(res => {
          console.log(this.foot,91919);

          this.formfooter = res.rows

          // this.bannerList = res.rows
      })
  },
  },
    mounted() {
      this.getListBanner();
      this.getlistCopyright()
    },
};
</script>

<style lang="less">
.exhibition-contact {
  .nav-bar {
    display: flex;
    height: 100px;
    background: #ffffff;
    padding: 0px 120px;
    max-width: 1450px;
    margin: auto;

    .activate-nav {
      background: #b3b3b3;
      color: #ffffff !important;
    }

    .bar-iten {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2f2f2f;
      padding: 0 30px;
      cursor: pointer;
    }
  }

  .rich-contact {
    padding: 60px 120px;
    background: #edeef2;

    .video-html {
      background: #ffffff;
      padding: 30px;
      border-radius: 30px;
      max-width: 1450px;
      margin: auto;

      // height: 600px;
      .video-contact {
        height: 600px;
        background-color: #000;
      }

      .html-contact {
        padding-top: 20px;

        img {
          max-width: 100%;
        }
      }
    }
  }
}
.footer {

  padding: 80px 120px;
  background-color: #e4e4ec;
  .footers{
      max-width:1450px;
      margin: auto;
  }

  .footer-fl-titler {
      h1 {
          font-size: 31px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #13141A;
          margin: 0;
      }

      h2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #13141A;
      }
  }

  .footer-container {
      display: flex;
      justify-content: space-between;

      border-bottom: 2px solid;

      .fl {
          .phone {
              margin-top: 45px;
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #13141A;
          }

          .phone-num {
              margin: 20px 0;
              font-size: 31px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #13141A;
          }

          .text {
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #13141A;
          }
      }



      .fr {
          .fr-h2 {
              font-size: 20px;
              margin-left:10px;
          }

          .qr-containter {
              display: flex;
              margin-top: 20px;

              .qr-item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin: 0 10px;

                  img {
                      width: 130px;
                      height: 130px;
                  }

                  span {
                      margin-top: 10px;
                      text-align: center;
                      font-size: 12px;
                  }

              }
          }
      }

  }

  .filing{
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 16px;
      .filinglink{
          cursor: pointer;
          color: #13141A;
          text-decoration: none;
      }
  }
}
</style>

