<template>
    <div>
    <div class="new-deatils">
        <div class="new-list">
            <div class="newdfl">
                <div class="newbg">
                    <div class="new-text">
                        <div style='line-height:1.42'>{{ detail.time }}</div>
                        <!-- <div style="margin-top: 10px;">新闻动态</div> -->
                    </div>
                </div>
            </div>
            <div class="newdfr">
                <div style="padding-right: 20px;padding-top:0px;"  v-html="detail.body" :class="['quill', 'ql-editor']"></div>
            </div>
        </div>
        
    </div>
    <div class="footer">
        <div class="footers">
        <div class="footer-fl-titler">
            <h1  >{{formfooter.web_footname}} </h1>
            <!-- <h2>Office of the Foreign Affairs Commission of Pengzhou Municipal </h2> -->
        </div>


        <div class="footer-container">
            <div class="fl">

                <div class="phone" v-if="foot == 'zh'">咨询电话：</div>
                <div class="phone" v-if="foot == 'en'">Consultation hotline: </div>
                
                <div class="phone-num">{{ formfooter.web_phone }}</div>

                <div class="text">
                    <div><span v-if="foot == 'zh'">参观地址：{{ formfooter.web_address }}</span>
                    <span v-if="foot == 'en'">Visit address: {{ formfooter.web_address }}</span></div>
                    <div style="margin-top: 20px;"><span v-if="foot == 'zh'" >参观时间：{{ formfooter.web_qq }}</span>
                    <span v-if="foot == 'en'" >Visit time: {{ formfooter.web_qq }}</span></div>
                </div>

                <div class="text" style="margin: 24px 0;">
                    <span v-if="foot == 'zh'">网址：{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'en'">Website:{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'zh'" style="margin-left: 110px;">邮编：611900 </span>
                    <span v-if="foot == 'en'" style="margin-left: 110px;">Postal Code: 611900</span>
                    <span v-if="foot == 'zh'" style="margin-left: 76px;">邮箱：{{ formfooter.web_email }}</span>
                    <span v-if="foot == 'en'" style="margin-left: 76px;">Email: {{ formfooter.web_email }}</span>
                </div>
            </div>

            <div class="fr">
                <div v-if="foot == 'zh'" class="fr-h2">获取更多最新信息</div>
                <div v-if="foot == 'en'" class="fr-h2">Get more up-to-date information</div>

                <div class="qr-containter">
                    <div class="qr-item">
                        <img src="http://lanzhi.580ck.com/static/images/ys3.png" alt="">

                        <span v-if="foot == 'zh'">投资彭州</span>
                        <span v-if="foot == 'en'">Investment in Pengzhou</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys2.png" alt="">

                        <span v-if="foot == 'zh'">彭州发布</span>
                        <span v-if="foot == 'en'">Pengzhou Release</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys1.jpg" alt="">

                        <span v-if="foot == 'zh'">品鉴彭州</span>
                        <span v-if="foot == 'en'">Tasting Pengzhou</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="filing">
            <span>
                {{ formfooter.web_copyright }}
            </span>
            <a class="filinglink" target="_blank" :href="formfooter.web_recordnum">{{ formfooter.web_recordnum}}</a>


        </div>
        </div>



    </div>
    </div>
</template>

<script>

import { getNews } from "@/api/news";
import { ApiDetail,listCopyright } from '@/api/index'
export default {
    data() {
        return {
            detail: "",
            activate: 3,
            formfooter:{},
            foot:localStorage.getItem('languageSet')
        }
    },
    created() {
        this.getList();
        this.getlistCopyright()
    },
    methods: {
        getlistCopyright(){
            listCopyright({
                pageNum: 1,
                pageSize: 1,
                type:localStorage.getItem('languageSet') || 'zh',
            }).then(res => {
                console.log(this.foot,91919);
      
                this.formfooter = res.rows
      
                // this.bannerList = res.rows
            })
        },
        getList() {
            console.log(this.$route.query.id);
            //   this.content =  localStorage.getItem(deatil)

            getNews({id:this.$route.query.id,type:localStorage.getItem('languageSet') || 'zh',}).then(res => {
                console.log(res,1111);

                this.detail = res.data
            })
        }
    }
}
</script>

<style lang="less">
.new-deatils {
    padding: 10px 64px 60px;
    background-color: #B2B2BA;

    .new-list {
        display: flex;
        min-height: 960px;
        background-color: #fff;
        max-width:1450px;
        margin: auto;

        .newdfl {
            width: 800px;
            height: 800px;
            padding-top: 60px;
            .newbg {
                height: 860px;
                background-repeat: no-repeat;
                // background-position: 0px 26px;
                background-size: contain;
                background-image: url(../assets/shade.png);

                .new-text {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #232323;
                    margin-left: 200px;
                    // margin-top: 60px;
                }
            }
        }



        .newdfr {
            margin-top: 60px;
            padding-right: 20px;
            flex-shrink: 0;
            width: 50%;

            img {
                max-width: 100%;
            }
        }
    }


}
.footer {

    padding: 80px 120px;
    background-color: #e4e4ec;
    .footers{
        max-width:1450px;
        margin: auto;
    }

    .footer-fl-titler {
        h1 {
            font-size: 31px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #13141A;
            margin: 0;
        }

        h2 {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #13141A;
        }
    }

    .footer-container {
        display: flex;
        justify-content: space-between;

        border-bottom: 2px solid;

        .fl {
            .phone {
                margin-top: 45px;
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #13141A;
            }

            .phone-num {
                margin: 20px 0;
                font-size: 31px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #13141A;
            }

            .text {
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #13141A;
            }
        }



        .fr {
            .fr-h2 {
                font-size: 20px;
                margin-left:10px;
            }

            .qr-containter {
                display: flex;
                margin-top: 20px;

                .qr-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 0 10px;

                    img {
                        width: 130px;
                        height: 130px;
                    }

                    span {
                        margin-top: 10px;
                        text-align: center;
                        font-size: 12px;
                    }

                }
            }
        }

    }

    .filing{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 16px;
        .filinglink{
            cursor: pointer;
            color: #13141A;
            text-decoration: none;
        }
    }
}
/deep/ .ql-editor{
    padding-top:0!important
}
</style>