import request from '@/utils/request'

// 查询公告列表
export function listNews(query) {
    return request({
      url: '/Api/news_list',
      method: 'get',
      params: query
    })
  }
  

  // 查询公告详细
export function getNews(query) {
  return request({
    url: '/Api/news_detail?id=' + query.id + '&type=' + query.type,
    method: 'get'
  })
}