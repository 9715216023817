import request from '@/utils/request'

// 查询视频
export function listIndex(query) {
    return request({
        url: '/Api/index_list',
        method: 'get',
        params: query
    })
}

// 查询参数列表
export function listCopyright(query) {
    return request({
      url: '/Api/unit_list',
      method: 'get',
      params: query
    })
  }

  // 查询公告列表
export function listCity(query) {
    return request({
      url: '/Api/city_list',
      method: 'get',
      params: query
    })
  }
  export function listLingshi(query) {
    return request({
      url: '/Api/lingshi_list',
      method: 'get',
      params: query
    })
  }
  export function ApiDetail(query) {
    return request({
      url: '/Api/api_details',
      method: 'get',
      params: query
    })
  }
  