<template>
    <div class="nar-content">
        <div class="bax-content">
            <div class="bar-fl">
                <img :src="formdetail.web_logo" alt="" srcset="">
            </div>
            <div class="bar-fr">
                <div class="bar-item" :class="activate == 1 ? 'bar-activate' : ''" @click="activateClick(1, '/')">{{
                    $t('index.nav.nav1') }}</div>
                <div class="bar-item" :class="activate == 2 ? 'bar-activate' : ''" @click="activateClick(2, '/jinpeng')">{{
                    $t('index.nav.nav2') }}</div>
                <!-- international -->
                <div class="bar-item" :class="activate == 3 ? 'bar-activate' : ''" @click="activateClick(3, '/internationals')">{{
                    $t('index.nav.nav3') }}</div>
                <div class="bar-item" :class="activate == 4 ? 'bar-activate' : ''" @click="activateClick(4, '/exhibition')">
                    {{ $t('index.nav.nav4') }}</div>
                <div class="bar-item" :class="activate == 5 ? 'bar-activate' : ''" @click="activateClick(5, '/news')">{{
                    $t('index.nav.nav5') }}</div>

                <div class="bar-lag-content">
                    <div class="bar-lag" @click="languageCli">
                        <span :class="lang == 'zh' ? 'fs-bold' : ''">CN</span>
                        <span>/</span>
                        <span :class="lang == 'en' ? 'fs-bold' : ''">EN</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { listIndex } from '@/api/index'
import { listCopyright } from '@/api/index'
export default {
    inject: ['reload'],
    data() {
        return {
            formdetail:{},
            activate: 1,
            lang: '',
            
        }
    },
    created() {
        console.log(this.$route.path,91919);
        this.$router.beforeEach((to, from, next) => {

            if(to.path == '/international'){
                this.activate = 3
            }

            if(to.path == '/exhibition'){
                this.activate = 4
            }
            console.log('路由发生了改变',to.path)
            // 这里可以编写处理路由变化的逻辑
            
            next(); // 必须调用next()才能完成路由切换
        })
    },
    mounted(){
        this.getlistCopyright()
    },
    methods: {
        getlistCopyright(){
            listCopyright({
                pageNum: 1,
                pageSize: 1
            }).then(res => {
                

                this.formdetail = res.rows

                // this.bannerList = res.rows
            })
        },

      


        activateClick(index, path) {
            console.log(index, path,789)
            this.activate = index
            this.$router.push({ path: path, query: { menu: index } })
            this.reload()
        },

        languageCli() {
            // this.lang 

            let lan = this.lang
            lan = lan == 'zh' ? 'en' : 'zh'
            this.lang = lan
            localStorage.setItem("languageSet", lan)
            console.log("lan", lan);
            this.$i18n.locale = lan
            this.reload()

        }
    }
}
</script>

<style lang="less">
.nar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 9999;
    background-color: #FFFFFF;

    .bax-content {

        display: flex;
        justify-content: space-between;
        padding: 0 120px;
        height: 100px;
        max-width:1450px;
        margin: auto;
    }

    .bar-fl {
        // float: left;
        padding: 16px 0;

        img {
            width: 340px;
        }
    }

    .bar-fr {
        // float: right;
        height: 100%;
        display: flex;


        .bar-item {
            display: flex;
            align-items: center;
            padding: 0 20px;
            font-size: 16px;
            cursor: pointer;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2F2F2F;

        }

        .bar-activate {

            color: #FFFFFF;
            background: #A2BD3C;

        }

        .bar-lag-content {
            display: flex;
            align-items: center;
            margin-left: 20px;

            .bar-lag {
                width: 125px;
                height: 26px;
                background: #A2BB43;
                border-radius: 13px;
                color: #FFFFFF;
                line-height: 26px;
                text-align: center;
                font-size: 19px;
                cursor: pointer;
            }

            .fs-bold {
                font-weight: bold;
            }

        }

    }
}
</style>