<template>
  <div class="international">
    <!-- 轮播图 -->
    <!-- slideshow -->
    <!-- <div style="padding-bottom: 84px;">
      <slideshow></slideshow>
    </div> -->


    <div class="rich-contact">
      <div v-html="datadetail.body" :class="['quill', 'ql-editor']"></div>
    </div>
    <div class="footer">
        <div class="footers">
        <div class="footer-fl-titler">
            <h1  >{{formfooter.web_footname}} </h1>
            <!-- <h2>Office of the Foreign Affairs Commission of Pengzhou Municipal </h2> -->
        </div>


        <div class="footer-container">
            <div class="fl">

                <div class="phone" v-if="foot == 'zh'">咨询电话：</div>
                <div class="phone" v-if="foot == 'en'">Consultation hotline: </div>
                
                <div class="phone-num">{{ formfooter.web_phone }}</div>

                <div class="text">
                    <div><span v-if="foot == 'zh'">参观地址：{{ formfooter.web_address }}</span>
                    <span v-if="foot == 'en'">Visit address: {{ formfooter.web_address }}</span></div>
                    <div style="margin-top: 20px;"><span v-if="foot == 'zh'" >参观时间：{{ formfooter.web_qq }}</span>
                    <span v-if="foot == 'en'" >Visit time: {{ formfooter.web_qq }}</span></div>
                </div>

                <div class="text" style="margin: 24px 0;">
                    <span v-if="foot == 'zh'">网址：{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'en'">Website:{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'zh'" style="margin-left: 110px;">邮编：611900 </span>
                    <span v-if="foot == 'en'" style="margin-left: 110px;">Postal Code: 611900</span>
                    <span v-if="foot == 'zh'" style="margin-left: 76px;">邮箱：{{ formfooter.web_email }}</span>
                    <span v-if="foot == 'en'" style="margin-left: 76px;">Email: {{ formfooter.web_email }}</span>
                </div>
            </div>

            <div class="fr">
                <div v-if="foot == 'zh'" class="fr-h2">获取更多最新信息</div>
                <div v-if="foot == 'en'" class="fr-h2">Get more up-to-date information</div>

                <div class="qr-containter">
                    <div class="qr-item">
                        <img src="http://lanzhi.580ck.com/static/images/ys3.png" alt="">

                        <span v-if="foot == 'zh'">投资彭州</span>
                        <span v-if="foot == 'en'">Investment in Pengzhou</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys2.png" alt="">

                        <span v-if="foot == 'zh'">彭州发布</span>
                        <span v-if="foot == 'en'">Pengzhou Release</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys1.jpg" alt="">

                        <span v-if="foot == 'zh'">品鉴彭州</span>
                        <span v-if="foot == 'en'">Tasting Pengzhou</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="filing">
            <span>
                {{ formfooter.web_copyright }}
            </span>
            <a class="filinglink" target="_blank" :href="formfooter.web_recordnum">{{ formfooter.web_recordnum}}</a>


        </div>
        </div>



    </div>
  </div>
</template>
  
<script>
import Slideshow from '@/components/slideshow.vue';
import { ApiDetail,listCopyright } from '@/api/index'
export default {
  components: {
    Slideshow

  },
  data() {
    return {
      id: localStorage.getItem("internationalStorage"),
      datadetail:{},
      formfooter:{},
            foot:localStorage.getItem('languageSet')
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.detailin()
    this.getlistCopyright()
  },
  methods: {
    getlistCopyright(){
      listCopyright({
          pageNum: 1,
          pageSize: 1,
          type:localStorage.getItem('languageSet') || 'zh',
      }).then(res => {
          console.log(this.foot,91919);

          this.formfooter = res.rows

          // this.bannerList = res.rows
      })
  },
    detailin() {
      ApiDetail({
                id: localStorage.getItem("internationalStorage"),
                type: localStorage.getItem('languageSet') || 'zh',
            }).then(res => {
                this.datadetail = res.rows

                console.log(res);
            })
        },
  }
}
</script>
  
<style lang="less">
.international {
  .rich-contact {
    padding: 30px 120px;
    background: #FFFFFF;
    max-width:1450px;
        margin: auto;

    img{
      max-width: 100%;
    }

    div {
      background: #FFFFFF;

      border-radius: 30px;
      // height: 600px;
    }
  }
}
.footer {

  padding: 80px 120px;
  background-color: #e4e4ec;
  .footers{
      max-width:1450px;
      margin: auto;
  }

  .footer-fl-titler {
      h1 {
          font-size: 31px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #13141A;
          margin: 0;
      }

      h2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #13141A;
      }
  }

  .footer-container {
      display: flex;
      justify-content: space-between;

      border-bottom: 2px solid;

      .fl {
          .phone {
              margin-top: 45px;
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #13141A;
          }

          .phone-num {
              margin: 20px 0;
              font-size: 31px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #13141A;
          }

          .text {
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #13141A;
          }
      }



      .fr {
          .fr-h2 {
              font-size: 20px;
              margin-left:10px;
          }

          .qr-containter {
              display: flex;
              margin-top: 20px;

              .qr-item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin: 0 10px;

                  img {
                      width: 130px;
                      height: 130px;
                  }

                  span {
                      margin-top: 10px;
                      text-align: center;
                      font-size: 12px;
                  }

              }
          }
      }

  }

  .filing{
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 16px;
      .filinglink{
          cursor: pointer;
          color: #13141A;
          text-decoration: none;
      }
  }
}
</style>