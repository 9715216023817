import request from '@/utils/request'

// 查询公告列表
export function listJinpeng(query) {
    return request({
      url: '/Api/jinpeng_list',
      method: 'get',
      params: query
    })
  }
  

