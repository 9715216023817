<template>
<div >
  <div class="news-pages">
    <div class="news-contact">

      <div class='nei'>

      <div class="news-title">
        <span class="cn">{{$t('news.title') }}</span>
        <!-- <span class="en">新闻动态</span> -->
      </div>


      <div class="new-item" v-for="(item, index) in newsList" :key="index" @click="goDetail(item)">
        <div class="fl">
          <img :src="item.art_pic" alt="">
        </div>
        <div class="fr">
          <div class="time">{{ item.time }}</div>
          <h3 class="title">{{ item.art_title }} </h3>
          <p class="describe">{{ item.art_description }}</p>

          <div class="decoration">彭州外事办</div>
        </div>
      </div>

      <div class="pages-contact">
        <el-pagination :page-size="queryParams.pageSize" @current-change="handleSizeChange" :pager-count="11"
          layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>

      </div>
      
    </div>
    



  </div>
  <div class="footer">
        <div class="footers">
        <div class="footer-fl-titler">
            <h1  >{{formfooter.web_footname}} </h1>
            <!-- <h2>Office of the Foreign Affairs Commission of Pengzhou Municipal </h2> -->
        </div>


        <div class="footer-container">
            <div class="fl">

                <div class="phone" v-if="foot == 'zh'">咨询电话：</div>
                <div class="phone" v-if="foot == 'en'">Consultation hotline: </div>
                
                <div class="phone-num">{{ formfooter.web_phone }}</div>

                <div class="text">
                    <div><span v-if="foot == 'zh'">参观地址：{{ formfooter.web_address }}</span>
                    <span v-if="foot == 'en'">Visit address: {{ formfooter.web_address }}</span></div>
                    <div style="margin-top: 20px;"><span v-if="foot == 'zh'" >参观时间：{{ formfooter.web_qq }}</span>
                    <span v-if="foot == 'en'" >Visit time: {{ formfooter.web_qq }}</span></div>
                </div>

                <div class="text" style="margin: 24px 0;">
                    <span v-if="foot == 'zh'">网址：{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'en'">Website:{{ formfooter.web_contacts }}</span>
                    <span v-if="foot == 'zh'" style="margin-left: 110px;">邮编：611900 </span>
                    <span v-if="foot == 'en'" style="margin-left: 110px;">Postal Code: 611900</span>
                    <span v-if="foot == 'zh'" style="margin-left: 76px;">邮箱：{{ formfooter.web_email }}</span>
                    <span v-if="foot == 'en'" style="margin-left: 76px;">Email: {{ formfooter.web_email }}</span>
                </div>
            </div>

            <div class="fr">
                <div v-if="foot == 'zh'" class="fr-h2">获取更多最新信息</div>
                <div v-if="foot == 'en'" class="fr-h2">Get more up-to-date information</div>

                <div class="qr-containter">
                    <div class="qr-item">
                        <img src="http://lanzhi.580ck.com/static/images/ys3.png" alt="">

                        <span v-if="foot == 'zh'">投资彭州</span>
                        <span v-if="foot == 'en'">Investment in Pengzhou</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys2.png" alt="">

                        <span v-if="foot == 'zh'">彭州发布</span>
                        <span v-if="foot == 'en'">Pengzhou Release</span>
                    </div>
                    <div class="qr-item">
                    <img src="http://lanzhi.580ck.com/static/images/ys1.jpg" alt="">

                        <span v-if="foot == 'zh'">品鉴彭州</span>
                        <span v-if="foot == 'en'">Tasting Pengzhou</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="filing">
            <span>
                {{ formfooter.web_copyright }}
            </span>
            <a class="filinglink" target="_blank" :href="formfooter.web_recordnum">{{ formfooter.web_recordnum}}</a>


        </div>
        </div>



    </div>
    </div>
</template>

<script>


import { listNews } from "@/api/news";
import { ApiDetail,listCopyright } from '@/api/index'
export default {
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 2,
        type: localStorage.getItem('languageSet') || 'zh',
        newsTitle: undefined,
        createBy: undefined,
        status: undefined
      },
      newsList: [],
      total: 0,
      formfooter:{},
            foot:localStorage.getItem('languageSet')
    }
  },
  created() {
    this.getList();
    this.getlistCopyright()
  },
  methods: {
    getlistCopyright(){
      listCopyright({
          pageNum: 1,
          pageSize: 1,
          type:localStorage.getItem('languageSet') || 'zh',
      }).then(res => {
          console.log(this.foot,91919);

          this.formfooter = res.rows

          // this.bannerList = res.rows
      })
  },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);

      this.queryParams.pageNum = val
      this.getList()
    },
    /** 查询公告列表 */
    getList() {
      this.loading = true;
      listNews(this.queryParams).then(response => {
        this.newsList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    goDetail(item) {
      console.log(item.id);
      this.$router.push({ path: "/newdetails", query: { id: item.id,type:localStorage.getItem('languageSet') || 'zh' } })
    }
  }

}
</script>

<style lang="less">
.news-pages {
  background: #edeef2;
  padding: 6px 120px 0;

  .news-contact {
    padding: 60px 0;

    background-color: #fff;
    max-width:1450px;
        margin: auto;


    .news-title {
      display: flex;
      align-items: center;

      .cn {
        font-size: 40px;
        font-family: Helvetica;
        font-weight: bold;
        color: #4D4D4D;
      }

      .en {
        font-size: 120px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4D4D4D;
        margin-left: 36px;
        margin-top: -9px;
      }
    }

    .new-item {
      display: flex;
      padding: 52px 0;
      border-bottom: 1px solid #C3C3C3;
      cursor: pointer;


      .fl {
        // flex: 1;
        flex-shrink: 0;
        width: 322px;
        // height: 320px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .fr {
        margin-left: 62px;

        .time {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #191919;
        }

        .title {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #191919;
        }

        .describe {
          font-size: 18px;
          color: #6F6F6F;

        }

        .decoration {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-decoration: underline;
          color: #6F6F6F;
        }
      }
    }
  }

  .pages-contact {
    width: 100%;
    text-align: center;
    padding-top: 60px;

  }
}
.footer {

  padding: 80px 120px;
  background-color: #e4e4ec;
  .footers{
      max-width:1450px;
      margin: auto;
  }

  .footer-fl-titler {
      h1 {
          font-size: 31px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #13141A;
          margin: 0;
      }

      h2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #13141A;
      }
  }

  .footer-container {
      display: flex;
      justify-content: space-between;

      border-bottom: 2px solid;

      .fl {
          .phone {
              margin-top: 45px;
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #13141A;
          }

          .phone-num {
              margin: 20px 0;
              font-size: 31px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #13141A;
          }

          .text {
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #13141A;
          }
      }



      .fr {
          .fr-h2 {
              font-size: 20px;
          }

          .qr-containter {
              display: flex;
              margin-top: 20px;

              .qr-item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin: 0 10px;

                  img {
                      width: 130px;
                      height: 130px;
                  }

                  span {
                      margin-top: 10px;
                      text-align: center;
                      font-size: 12px;
                  }

              }
          }
      }

  }

  .filing{
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 16px;
      .filinglink{
          cursor: pointer;
          color: #13141A;
          text-decoration: none;
      }
  }
}
.nei{
  padding:0 60px
}
</style>

