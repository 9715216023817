<template>
  <div id="app">
    <navigation-bar></navigation-bar>
    <router-view class="body-view" v-if="isRouterShow"></router-view>
    
  </div>
</template>
<script>
import NavigationBar from "@/components/navigationBar.vue";

export default {
  components: {

    NavigationBar,
   
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterShow: true
    }
  },
  props: {},
  methods: {
    async reload() {
      this.isRouterShow = false
      await this.$nextTick()
      this.isRouterShow = true
    }
  }
}
</script>
<style lang="less">
// 使用完整版的CSS reset库可能会影响到一些自定义样式，因此可以选择只引入某些部分。例如，如果只想消除浏览器的默认样式，可以使用以下代码：
@import '~normalize.css';
/* 引入normalize.css */
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.bubble.css";

.body-view {
  margin-top: 100px;
}


.ql-lineheight-1 {
  line-height: 1;
}

.ql-lineheight-1-5 {
  line-height: 1.5;
}

.ql-lineheight-1-75 {
  line-height: 1.75;
}

.ql-lineheight-2 {
  line-height: 2;
}

.ql-lineheight-3 {
  line-height: 3;
}

.ql-lineheight-4 {
  line-height: 4;
}

.ql-lineheight-5 {
  line-height: 5;
}
</style>
