<template>
    <div class="slideshow-content">
        <!--  :interval="5000"  always/hover/never-->
        <el-carousel arrow="always" :autoplay="false" height="750px" :interval="8000">
            <el-carousel-item v-for="item in bannerList" :key="item.id" :style="{ backgroundColor: backgroundColor }">
                <img class="slide-img" :src="item.slide_pic" alt="">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    props: {
        bannerList: {
            default: []
        },
        backgroundColor: {
            default: '#FFFFFF'
        }
    }
}
</script>

<style lang="less">
.slideshow-content {
    padding: 0 120px;
    max-width:1450px;
        margin: auto;

    .el-carousel__container {
        border-radius: 30px;
        overflow: hidden;
        // background-color: red;

    }

    .el-carousel__item {
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: #FFFFFF;
    }


    .el-carousel__arrow {
        width: 64px;
        height: 64px;

        font-size: 26px;
        // bottom: 10px;
    }



    .slide-img {

        width: 100%;
        //width: 1450px;
        // height: 100%;


    }
}
</style>